import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Button from '../../components/button';
import { useMe } from '../../hooks/useMe';
import { useApi } from '../../hooks/useApi';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { toast, Zoom } from 'react-toastify';
import AccountLayout from './components/accountSettingsLayout'
import 'twin.macro'

const CustomDomain = ({...props}) => {

	const { me, refreshMe } = useMe();
	const { register, errors, handleSubmit, setValue, formState, reset } = useForm({
		defaultValues: {
			customDomain: me && me.customDomain,
			directDownload: me && me.directDownload
		}
	})

	const { callApi } = useApi()
	const [error, setError] = useState()
	const [spin, setSpin] = useState(false)
 
	useEffect(() => {
		refreshMe();
	}, [refreshMe]);

	useEffect(() => {
		if (me) {
			setValue('customDomain', me.customDomain)
			setValue('directDownload', Boolean(me.directDownload))
		}
	}, [me, setValue]);

	const onSubmit = (data) => {
		setError(null)
		setSpin(true)
		callApi({
			method: 'patch',
			namespace: 'me',
			payload: {
				customDomain: data.customDomain,
				directDownload: data.directDownload
			}
		})
			.then((result) => {
				reset(result.data);
				refreshMe();
				toast.info('Custom domain settings saved successfully!', {
					position: 'top-right',
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					transition: Zoom
				});
			})
			.catch((err) => {
				if (err.response && err.response.data) {
					setError(err.response.data);
				}
				else {
					console.log(err);
					setError(err);
				}
			})
			.finally(() => {
				setSpin(false)
			})
	};

	return <AccountLayout tab="customdomain" {...props}>
		<div>
		<p>You can associate a custom domain name with your file.io account to enable secure branded download links.</p>
		<p>For example:</p>
		<code><pre tw="my-4">https://files.yourcompany.com/ABgYui093Xk7</pre></code>
		<p tw="mt-4">To do this, enter the domain name you want to use in the "Custom Domain Name" field below and save. You will then need to create a CNAME record for the domain name and configure it to point to <code>sni.file.io</code>. This is typically done in the admin area of the website where you bought or manage your domain name.</p>
		<p>Note: It can take a few minutes or longer for the CNAME record to move through DNS and become active.</p>
		<form tw="my-4" onSubmit={handleSubmit(onSubmit)}>
			<div tw="flex flex-col justify-start mb-4">
				<label htmlFor="customDomain">Custom Domain Name</label>
				<input
					name="customDomain"
					placeholder="Example: files.yourcompany.com"
					maxLength="253"
					ref={register} />
				<label tw="inline"><input
					name="directDownload"
					type="checkbox"
					tw="w-auto! inline! mr-2 mb-0!"
					ref={register} />Allow direct downloads when using the custom domain name.</label>
			</div>
			{error && <div className="error">{error.message || 'An error occurred.'}</div>}
			<div tw="flex flex-col md:flex-row">
				<Button special disabled={!formState.isDirty} icon={faSave} spin={spin}>Save Custom Domain Settings</Button>
			</div>
		</form>
		</div>
	</AccountLayout>

}

export default CustomDomain
